<template>
  <div class="common-pop" id="iframe-track">
    <el-dialog title="" :close-on-click-modal="false"  :modal-append-to-body='false' :visible.sync="visible" width="96%" center>
      <iframe :src="url" frameborder="0"  width="100%" height="100%"></iframe>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      url: ''
    }
  },
  methods: {
    init (url) {
      this.visible = true
      this.url = url
    }
  }
}
</script>
<style lang="less">
#iframe-track {
  .el-dialog {
    height: 95%;
    .el-dialog__header {
      padding: 0;
      .el-dialog__headerbtn {
        top: 10px;
        background: #fff !important;
        border-radius: 50%;
        height: 24px;
        display: inline-block;
        width: 24px;
        font-size: 20px;
      }
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
}
</style>
